import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { SelectButton } from "primereact/selectbutton";
import { Checkbox } from "primereact/checkbox";
import useScanDetection from "use-scan-detection-react18";
import debounce from "lodash/debounce";
import { Button } from "primereact/button";
import { useReactToPrint } from "react-to-print";
import { Divider } from "primereact/divider";
import { useRef } from "react";

export default function AddProductForm({
  formik,
  filterSubCategories,
  specificProduct,
}) {
  const { categories } = useSelector((state) => state.CategoryReducer);
  const { productId } = useParams();
  const [scanProductBarcode, setScanProductBarcode] = useState("");
  const lastKeyPressTime = useRef(0);
  const keyPressCount = useRef(0);
  const [localTitle, setLocalTitle] = useState(formik.values.title);

  const purchasePriceRef = useRef(null);

  useScanDetection({
    onComplete: (code) => {
      setScanProductBarcode(code);
    },
    minLength: 13, // EAN13
  });

  useEffect(() => {
    if (scanProductBarcode.length > 0) {
      formik.setFieldValue("title", scanProductBarcode || "");
      setScanProductBarcode(""); // Clear the barcode after setting // Clear the barcode after setting
    }
  }, [scanProductBarcode, formik]);

  const statusOptions = [
    { name: "Active", value: true },
    { name: "Deactive", value: false },
  ];

  const debouncedUpdate = useCallback(
    debounce((value) => {
      formik.setFieldValue("title", value);
    }, 300),
    [formik]
  );
  const handleTitleChange = (e) => {
    const now = Date.now();
    const newValue = e.target.value;
    if (newValue.length <= 25 && /^[a-zA-Z0-9\s]*$/.test(newValue)) {
      setLocalTitle(newValue);
      debouncedUpdate(newValue);
    }
  };
  const handlePrint = useReactToPrint({
    pageStyle:
      "@page { size: 2in 1in;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
    content: () => document.getElementById("receipt-component"),
  });

  const handlePurchasePriceClick = () => {
    if (purchasePriceRef.current) {
      // Find the input element within the InputNumber component
      const inputElement = purchasePriceRef.current.querySelector("input");
      if (inputElement) {
        setTimeout(() => {
          inputElement.select();
        }, 0);
      }
    }
  };

  useEffect(() => {
    if (formik.values.samePrice) {
      const updatedVariants = formik.values.varients.map((variant) => ({
        ...variant,
        price: formik.values.price,
      }));
      formik.setFieldValue("varients", updatedVariants);
    }
  }, [formik.values.price, formik.values.samePrice]);

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {productId ? "Edit Product" : "Add Product"}
        </span>
      </Divider>
      {/* <div className="px-4 pb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="w-full flex">
          <span className="p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              placeholder="Scan using barcode"
              className="rounded-r-none pl-12 w-full"
              value={scanProductBarcode}
              onChange={(e) => console.log(e.target.value)}
              // onChange={(e) => setScanProductBarcode(e.target.value)}
              onKeyUpCapture={(e) => {
                e.preventDefault();
                if (e.key === "Enter") {
                  // We already handle barcode scanning in useEffect
                }
              }}
            />
          </span>
          <Button
            icon="pi pi-arrow-right"
            className="rounded-l-none p-black-btn"
            type="button"
            onClick={() => setScanProductBarcode(scanProductBarcode)}
          />
        </div>
      </div> */}
      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="">
              Product Title
            </label>
            <span className="w-full">
              <InputText
                id="title"
                name="title"
                className="w-full text-lg p-primary-input"
                value={localTitle}
                onChange={handleTitleChange}
                maxLength={25}
              />
            </span>
          </div>
          {formik.touched?.title && formik.errors?.title && (
            <div className="p-error">{formik.errors?.title}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="articleName" className="">
              Article Number
            </label>
            <span className="w-full">
              <InputText
                id="articleName"
                name="articleName"
                className="w-full text-lg p-primary-input bg-gray-100"
                value={formik.values.articleName}
                readOnly
                disabled
              />
            </span>
          </div>
          <div className="text-sm text-gray-500 mt-1">
            Auto-generated article number
          </div>
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryId" className="">
              Select Category
            </label>
            <Dropdown
              id="categoryId"
              name="categoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.categoryId}
              onChange={formik.handleChange}
              options={categories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.categoryId && formik.errors?.categoryId && (
            <div className="p-error">{formik.errors?.categoryId}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="subCategoryId" className="">
              Select Sub Category
            </label>
            <Dropdown
              id="subCategoryId"
              name="subCategoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.subCategoryId}
              onChange={formik.handleChange}
              options={filterSubCategories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.subCategoryId && formik.errors?.subCategoryId && (
            <div className="p-error">{formik.errors?.subCategoryId}</div>
          )}
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="purchasePrice" className="">
              Purchase Price
            </label>
            <span className=" w-full">
              <InputNumber
                id="purchasePrice"
                name="purchasePrice"
                className="w-full text-lg p-primary-input"
                value={formik.values.purchasePrice}
                onValueChange={formik.handleChange}
                prefix="RS "
                disabled={!formik.values.samePurchasePrice}
              />
            </span>
          </div>
          {formik.touched?.purchasePrice && formik.errors?.purchasePrice && (
            <div className="p-error">{formik.errors?.purchasePrice}</div>
          )}
        </div> */}
        {/* <div className="mt-2">
            <Checkbox
              inputId="samePurchasePrice"
              name="samePurchasePrice"
              id="samePurchasePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePurchasePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePurchasePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="price" className="">
              Sale Price
            </label>
            <span className=" w-full" ref={purchasePriceRef}>
              <InputNumber
                id="price"
                name="price"
                className="w-full text-lg p-primary-input"
                value={formik.values.price || formik.values.varient.price}
                onValueChange={formik.handleChange}
                onClick={handlePurchasePriceClick}
                placeholder="Rs 0"
                prefix="RS "
                disabled={!formik.values.samePrice}
              />
            </span>
          </div>
          {formik.touched?.price && formik.errors?.price && (
            <div className="p-error">{formik.errors?.price}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="samePrice"
              name="samePrice"
              id="samePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div>
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="saleDiscount" className="">
              Sale Discount
            </label>
            <span className="w-full">
              <InputNumber
                id="saleDiscount"
                name="saleDiscount"
                className="w-full text-lg p-primary-input"
                value={formik.values.saleDiscount}
                onValueChange={(e) =>
                  formik.setFieldValue("saleDiscount", e.value)
                }
                prefix={"RS "}
                disabled={!formik.values.isSaleDiscount}
              />
            </span>
          </div>
          {formik.touched.saleDiscount && formik.errors.saleDiscount && (
            <div className="p-error">{formik.errors.saleDiscount}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="isSaleDiscount"
              name="isSaleDiscount"
              onChange={(e) => {
                formik.setFieldValue("isSaleDiscount", e.checked);
                if (!e.checked) {
                  formik.setFieldValue("saleDiscount", 0);
                }
              }}
              checked={formik.values.isSaleDiscount}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="isSaleDiscount" className="ml-2">
              Enable Sale Discount
            </label>
          </div>
        </div>

        {/*
        
          <div className="mt-2">
            <Checkbox
              inputId="isDiscountPercentage"
              name="isDiscountPercentage"
              id="isDiscountPercentage"
              onChange={formik.handleChange}
              checked={formik.values.isDiscountPercentage}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="isDiscountPercentage" className="ml-2">
              Discount in Percentage(%)
            </label>
          </div>
        </div> 

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="quantity" className="">
              Quantity
            </label>
            <span className=" w-full">
              <InputNumber
                id="quantity"
                name="quantity"
                className="w-full text-lg p-primary-input"
                value={formik.values.quantity}
                onValueChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.quantity && formik.errors?.quantity && (
            <div className="p-error">{formik.errors?.quantity}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="status" className="">
              Status
            </label>
            <SelectButton
              name="status"
              id="status"
              options={statusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.status}
              onChange={formik.handleChange}
            />
            {formik.touched.status && formik.errors.status && (
              <div className="p-error">{formik.errors.status}</div>
            )}
          </div>
        </div>

        <div className="col-span-1 lg:col-span-2">
          <div className="flex flex-col gap-2">
            <label htmlFor="description" className="">
              Description
            </label>
            <span className=" w-full">
              <InputText
                id="description"
                name="description"
                className="w-full text-lg p-primary-input"
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.description && formik.errors?.description && (
            <div className="p-error">{formik.errors?.description}</div>
          )}
        </div>
      </div>

      {productId &&
      specificProduct &&
      specificProduct?.barcode_img &&
      specificProduct?.barcode_url ? (
        <>
          <div className="hidden my-10 px-4 gap-10 font-bold justify-end">
            <div className=" ">
              <div
                className="text-[10px] overflow-hidden leading-none"
                id="receipt-component"
                style={{
                  width: "2in",
                  height: "1in",
                }}
              >
                <div
                  className="flex py-3 flex-col leading-none text-[15px] clear-none"
                  style={{ width: "2in", height: "0.6in" }}
                >
                  <div className="flex justify-center font-bold ">
                    {specificProduct?.title}
                  </div>
                  <div className="flex justify-center font-bold ">
                    <span>
                      Rs{" "}
                      {formik.values.price
                        ? formik.values.price.toLocaleString("en-IN")
                        : "0"}
                      /-
                    </span>
                  </div>
                  <div className="flex justify-center font-bold">
                    <span>Tehzeeb Designer</span>
                  </div>
                </div>
                <div className="flex justify-center ">
                  <img
                    src={`${specificProduct?.barcode_url}${specificProduct?.barcode_img}`}
                    className=""
                    style={{ width: "1.5in", height: "0.33in" }}
                  />
                </div>
              </div>
            </div>
            <div className="my-auto mx-6">
              <Button
                label="Print Barcode"
                icon="pi-print"
                className="p-black-btn"
                onClick={handlePrint}
                type="button"
              />
            </div>
          </div>

          {/* <div className="my-6 px-4 flex gap-8 justify-end">
            <div className=" ">
              <div
                style={{
                  width: "38mm",

                  height: "28mm",
                }}
              >
                <div className="flex font-bold mb-1 flex-col">
                  <div className="flex justify-center text-xl">
                    {specificProduct?.title}
                  </div>
                  <div className="flex gap-3 justify-center text-xl">
                    <span>
                      Rs {formik.values.price.toLocaleString("en-IN")}/-
                    </span>
                  </div>
                                                      <div className="flex gap-3 justify-center text-md">
                    <span>Tehzeeb Designer</span>
                  </div>
                </div>
                <div className="flex justify-center px-3" style={{}}>
                  <img
                    src={`${specificProduct?.barcode_url}${specificProduct?.barcode_img}`}
                    className=""
                                                          style={{ width: "42mm" ,height:"13mm" }}
                  />
                </div>
              </div>
            </div>
            <div className="my-auto mx-6">
              <Button
                label="Print Barcode"
                icon="pi pi-print"
                className="p-black-btn"
                onClick={handlePrint}
                type="button"
              />
            </div>
          </div> */}
        </>
      ) : null}
    </div>
  );
}
